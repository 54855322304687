import React, { useEffect, useState} from "react";
import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import ThunksCustomers from "../../../redux/actions/thunks/customer";
import { connect } from "react-redux";
import tableIcons from "../../../components/commons/ConstantsMaterialTable";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import TccBookingDetailTable from "./TccBookingDetailTable";
import ThunksReports from "../../../redux/actions/thunks/reports";
import  { Checkbox }  from "@material-ui/core";
import {NativeSelect} from "@material-ui/core";
import moment from "moment";
import Local from "../../../redux/actions/local";
import Report from "../../../redux/actions/local/report";
import { getMonday } from "../../../helpers/index";

function Customers({ range, update,getCompaniesAndCustomersByOrgId}) {
  const [load, setLoad] = useState(true);
  const [weekArray, setWeekArray]= useState([]);
  const [selectedWeek, setSelectedWeek] = useState(0);
  const [response, setResponse] = useState([]);

  useEffect(() => {
    getStartWeek();
  }, []);

  useEffect(()=>{
    if(weekArray && weekArray.length > 0){
      handleChange(1)
    }
  },[weekArray])

  const getStartWeek = () => {

    const lastDate = "2022-12-03";
    let today = moment(new Date());
    let finalWeeks = []
    let numb = 1;

    while (moment(today).isAfter(lastDate)) {
      const dayOfWeek = parseInt(today.day())
      const sundayDate = today;
      const mondayDate = moment(today).subtract(getMonday[dayOfWeek], "day");

      const monday = mondayDate.format('YYYY-MM-DD');
      const sunday = sundayDate.format('YYYY-MM-DD');
      
      const year = moment(sunday).format("YYYY");
      
      finalWeeks.push({
        start: monday,
        end: sunday,
        week: moment(monday).week(), 
        year: year})

      today = moment(mondayDate.subtract(1, 'day'));
      numb += 1;
    }
    setWeekArray(finalWeeks);
    setSelectedWeek(1)
    
  }
  console.log('=>',weekArray)
  // const getStartMonth = () => {
  //   let start = new Date("2022-12-03");
  //   let end= new Date();
  //   let startYear = start.getFullYear();
  //   let endYear = end.getFullYear();
  //   let monthArray = [];
  //   for(let i = startYear; i <= endYear; i++){
  //     if(i == startYear){
  //       let startMonth =new Date(start).getMonth();
  //       for(let k = startMonth; k <= 11 ; k++){
  //         monthArray.push({ year: i, month: k})
  //       }
  //     }
  //     if(i != endYear && i != startYear){
  //       for(let k = 0; k <= 11 ;k++){
  //         monthArray.push({ year: i, month: k})
  //       } 
  //     }
  //     if(i == endYear){
  //       let endMonth = new Date().getMonth();
  //       for(let k = 0; k <= endMonth ; k++){
  //         monthArray.push({ year: i, month: k})
  //       }
  //     }
  //   }
  //   const reversed = monthArray.reverse();
  //   setMonthArray(reversed);
  //   setSelectedMonth(1)

  // }
  const columns = [
    // { title: "Id", field: "id", editable: "never" },
    { title: "TCC Name", field: "companyName", editable: "never", type: "companyName" },
    { title: "Booking Volumn",  editable: "never", field: "volumn"},
    { title: "Total Revenue", editable: "never", field: "total"},
    { title: "Average Rate", field: "averageRate", editable: "never",},
  ];

  const handleChange = (selection) => {
      update("startDate",  weekArray[selection].start);
      update("endDate", weekArray[selection].end)
  }


  useEffect(()=>{
    if(range.startDate !== "" || range.endDate !== "") {
      console.log("here is get all", range)
      getAll(range.startDate, range.endDate)
    }
  },[range]);

  const getAll = async(start, end) =>{
    setLoad(true)
    const result = await getCompaniesAndCustomersByOrgId(start, end);
    setResponse(result);
    setLoad(false)
  }

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row"}}>
          <div style={{ marginRight:40, display:"flex", flexDirection:"row"}}>
            {/* <p>Week : </p> */}
            {/* <Checkbox 
            onChange={(e)=>{
              setWeek(e.target.checked);
              if(e.target.checked){
                handleChange("week", selectedWeek)
                setMonth(false);
              }else{
                setMonth(true);
                handleChange("month", selectedMonth)
              }
            }} 
            checked={week}/> */}
            <NativeSelect
              value={selectedWeek}
              style={{ width: 140, fontSize: 14}}
              onChange={(e)=>{
                setSelectedWeek(e.target.value);
                handleChange(e.target.value)
              }}
            >
              {
                weekArray && weekArray.length > 0 && weekArray.map((week, index)=> {
                  return <option value = {index} key={index}>Week {week.week} - {week.year}</option>
                })
              }
            </NativeSelect>
          </div>
          {/* <div style={{ display:"flex", flexDirection:"row"}}>
            <p>Month : </p>
            <Checkbox 
            onChange={(e)=>{
              setMonth(e.target.checked)
              if(e.target.checked){
                handleChange("month", selectedMonth)
                setWeek(false)
              }else{
                handleChange("week", selectedWeek)
                setMonth(true)
              }
              }} 
            checked={month}/>
            <NativeSelect
              value={selectedMonth}
              style={{ width: 80, fontSize: 14}}
              onChange={(e)=>{
                setSelectedMonth(e.target.value);
                handleChange("month", e.target.value)
              }}
            >
              {
                monthArray && monthArray.length > 0 && monthArray.map((month, index)=> {
                  return <option value = {index} key={index}>{month.year} - {month.month + 1 }</option>
                })
              }
            </NativeSelect>
          </div> */}
      </div>
      <MaterialTable
        icons={tableIcons}
        isLoading={load}
        columns={columns}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        data={response}
        detailPanel={(rowData) => {
          return (
            <TccBookingDetailTable rowData = {rowData}/>
          );
        }}
        options={{
          actionsColumnIndex: -1,
          search: false,
          detailPanelType: "single",
        }}
        title="Reports"
      />
    </>
  );
}

const mapState = (state) => ({
  range: state.reports
});

const mapDispatch = {
  getCustomers: ThunksCustomers.Customers,
  getCompanies: ThunksReports.getCompanies,
  update: Report.field.update,
  getCompaniesAndCustomersByOrgId: ThunksReports.getCompaniesAndCustomersByOrgId,
};

export default connect(mapState, mapDispatch)(Customers);
