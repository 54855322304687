import { useState } from "react";
import Button from "@material-ui/core/Button";
import UpdateBookingModal from "./UpdateBookingModal";
import CancelBooking from "./CancelBooking"
import { Box } from "@material-ui/core";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DuplicateBookingModal from "./DuplicateBookingModal";
import EditIcon from '@mui/icons-material/Edit';
// import ThunksDispatchers from "../../../redux/actions/thunks/dispatchers";
// import { connect } from "react-redux";
function UpdateBooking({ data, role }) {
  const [open, setOpen] = useState(false);
  const [duplicateOpen, setDuplicateOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDuplicateClickOpen = () => {
    // getDispatchersByBooking(data.id)
    setDuplicateOpen(true)
  }
  const handleDuplicateClose = () => {
    // getDispatchersByBooking(data.id)
    setDuplicateOpen(false)
  }

  return (
    <Box sx={{ display:"flex", flexDirection:"row"}}>
      <Button
        style={{
          minWidth: 40,
          borderRadius: 25,
          marginLeft: 4,
        }}
        size = "sm"
        onClick={handleClickOpen}
      >
        <EditIcon/>
      </Button>
      <Button
        style={{
          minWidth: 40,
          borderRadius: 25,
          marginLeft: 4,
        }}
        size = "sm"
        onClick={handleDuplicateClickOpen}
      >
      <ContentCopyIcon/>
      </Button>
      <CancelBooking bookingState={data}/>
      {data && open && (
        <UpdateBookingModal
          role={role}
          bookingState={data}
          open={open}
          onClose={handleClose}
        />
      )}

      {duplicateOpen && (
        <DuplicateBookingModal
          role={role}
          bookingState={data}
          open={duplicateOpen}
          onClose={handleDuplicateClose}
        />
      )}


    </Box>
  );
}

// Dispatcher in edit and duplicate booking in web should be removed
// const mapState = (state) => ({
//   bookingState: state.bookings.state,
// });
// const mapDispatch = (dispatch) => ({
//   getDispatchersByBooking: (bookingId) =>
//     dispatch(ThunksDispatchers.getDispatchersByBooking(bookingId)),
// });  

export default UpdateBooking;

