import { ErrorMessage, Field, Form, Formik } from "formik";
import { connect } from "react-redux";
import MOButtonV2 from "../../../../components/commons/MOButtonV2";
import ThunksBooking from "../../../../redux/actions/thunks/bookings";
import { apiKeyValidationSchema } from "../../../../validations/apiKeyValidation";
import mobioApi from "../../../../services";
import { toastMessage } from "../../../../helpers/toastMessage";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import * as Yup from "yup";

const RateModuleForm = ({
  integrationTypeData,
  setIntegrationTypeData = () => {},
  token,
  manageUrl,
}) => {
  const navigate = useNavigate();
  const [editAllow, setEditAllow] = useState(
    integrationTypeData?.connectionStatus
  );
  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      setSubmitting(true);
      const api = mobioApi(token);
      const response = await api.addRateIntegration({
        clientId: values.clientId,
        clientSecret: values.clientSecret,
        integrationType: integrationTypeData?.value,
        endpoint: values.endpoint,
      });
      setIntegrationTypeData({
        ...integrationTypeData,
        connectionStatus: true,
        id: response?.data?.data?.id,
      });

      toastMessage("success", response?.data?.msg);
    } catch (error) {
      toastMessage("error", error?.response?.data?.msg);
    } finally {
      setSubmitting(false);
    }
  };
  useEffect(() => {
    if (integrationTypeData) {
      setEditAllow(integrationTypeData?.connectionStatus);
    }
  }, [integrationTypeData]);
  const extendedValidationSchema = apiKeyValidationSchema.concat(
    Yup.object({
      endpoint: Yup.string()
        .url("Endpoint must be a valid URL")
        .required("Endpoint is required"),
    })
  );
  return (
    <Formik
      initialValues={{
        clientId: "",
        clientSecret: "",
        endpoint: "",
      }}
      onSubmit={handleSubmit}
      validationSchema={
        integrationTypeData.value == "Booking.com"
          ? extendedValidationSchema
          : apiKeyValidationSchema
      }
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form>
          <div>
            <div className="">
              {integrationTypeData.value == "Booking.com" && (
                <div className="input_field width-full ">
                  <Field
                    type="text"
                    name="endpoint"
                    placeholder="Api Endpoint *"
                    disabled={editAllow}
                    style={{
                      textIndent: "14px",
                      height: "60px",
                      backgroundColor: editAllow && "#F3F3F3",
                      border: editAllow && "none",
                    }}
                  />
                  <ErrorMessage
                    name="endpoint"
                    component="div"
                    className="formik-error-message"
                  />
                </div>
              )}
              <div
                className={
                  integrationTypeData?.value == "Booking.com"
                    ? "input_field width-full mt-24"
                    : "input_field width-full"
                }
              >
                <Field
                  type="text"
                  name="clientId"
                  placeholder="Client ID *"
                  disabled={editAllow}
                  style={{
                    textIndent: "14px",
                    height: "60px",
                    backgroundColor: editAllow && "#F3F3F3",
                    border: editAllow && "none",
                  }}
                />
                <ErrorMessage
                  name="clientId"
                  component="div"
                  className="formik-error-message"
                />
              </div>
              <div className="input_field width-full mt-24">
                <Field
                  type="text"
                  name="clientSecret"
                  placeholder="Client Secret *"
                  disabled={editAllow}
                  style={{
                    textIndent: "14px",
                    height: "60px",
                    backgroundColor: editAllow && "#F3F3F3",
                    border: editAllow && "none",
                  }}
                />
                <ErrorMessage
                  name="clientSecret"
                  component="div"
                  className="formik-error-message"
                />
              </div>
            </div>
            {integrationTypeData?.connectionStatus ? (
              <>
                <div className="mt-40 flex justify-content-between">
                  {/* <MOButtonV2
                    text="EDIT CREDENTIALS"
                    textColor="#595959"
                    borderRadius={8}
                    backgroundColor="transparent"
                    height={50}
                    radius={4}
                    widthSize={"22%"}
                    disabled={isSubmitting}
                    type="button"
                    fontSize={11}
                    border="1.5px solid #595959"
                    onClick={() => {
                      setEditAllow(!editAllow);
                    }}
                  /> */}
                  <MOButtonV2
                    text="MANAGE INTEGRATION"
                    textColor="#ffffff"
                    borderRadius={8}
                    backgroundColor="#29AEE6"
                    height={50}
                    radius={4}
                    widthSize={"100%"}
                    disabled={isSubmitting}
                    type="button"
                    fontSize={11}
                    onClick={() => {
                      navigate(manageUrl);
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="mt-40 flex justify-content-center">
                  <MOButtonV2
                    text="SAVE SETTINGS"
                    textColor="#ffffff"
                    borderRadius={8}
                    backgroundColor="#29AEE6"
                    height={50}
                    radius={4}
                    widthSize={"100%"}
                    disabled={isSubmitting}
                    type="submit"
                    fontSize={11}
                  />
                </div>
              </>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

const mapState = (state) => ({
  companyId: state.userInfo.user.id,
});

const mapDispatch = {
  bookingIntegration: ThunksBooking.BookingIntegration,
};

export default connect(mapState, mapDispatch)(RateModuleForm);
