import { Button } from "@material-ui/core";
import { NativeSelect } from "@mui/material";
import { useState, useEffect } from "react";
import getSymbolFromCurrency from "currency-symbol-map";

const Title = ({ sheetOptionStates, downloadSheet, selectedRecords }) => {
  const { currency, country, bank, setCurrency, setCountry, setBank } =
    sheetOptionStates;
  const [sheetStats, setSheetStats] = useState({
    totalRides: 0,
    totalAmount: 0,
  });

  function findTotal(records) {
    const newStats = {
      totalAmount: 0,
      totalRides: 0,
    };
    records.map((item) => {
      if (!item?.tableData?.disabled && item.tableData.checked) {
        newStats.totalAmount = newStats.totalAmount + parseFloat(item.amount);
        newStats.totalRides = newStats.totalRides + parseInt(item.totalRide);
      }
    });
    setSheetStats({ ...newStats });
  }

  useEffect(() => {
    findTotal(selectedRecords);
  }, [selectedRecords]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "20px",
        width: "100%",
      }}
    >
      <div style={{ fontSize: "1.3rem" }}>PAYOUTS</div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "12px",
          alignItems: "flex-end",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            columnGap: "8px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <NativeSelect
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          >
            <option value="">Country</option>
            <option value="NL">Netherlands</option>
            <option value="GB">Great Britain</option>
          </NativeSelect>

          <NativeSelect value={bank} onChange={(e) => setBank(e.target.value)}>
            <option value="">Bank</option>
            {country == "NL" ? (
              <>
                <option value="ING">ING</option>
                <option value="REVOLUT">Revolut</option>
              </>
            ) : (
              <option value="REVOLUT">Revolut</option>
            )}
          </NativeSelect>

          <NativeSelect
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
          >
            <option value="all">Currency</option>
            {country == "NL" ? (
              <option value="EUR">EUR</option>
            ) : country == "GBP" ? (
              <option value="GBP">GBP</option>
            ) : (
              <>
                <option value="EUR">EUR</option>
                <option value="GBP">GBP</option>
              </>
            )}
          </NativeSelect>

          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: "10px" }}
            onClick={() => downloadSheet(bank, currency, country)}
            disabled={
              !bank ||
              !country ||
              [...selectedRecords].findIndex(
                (item) => item?.tableData?.checked
              ) == -1
            }
          >
            Download Sheet
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          {sheetStats.totalAmount != 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                color: "#0000008A",
              }}
            >
              Total amount:
              <span
                style={{
                  fontWeight: "500",
                  color: "#0000008A",
                  marginLeft: "0.3rem",
                  fontSize: "0.875rem",
                }}
              >
                {currency == "all" ? "" : getSymbolFromCurrency(currency)}
                {sheetStats?.totalAmount && sheetStats?.totalAmount.toFixed(2)}
              </span>
            </div>
          )}
          {sheetStats.totalRides != 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "0.5rem",
                fontWeight: "bold",
                color: "#0000008A",
              }}
            >
              No Of Rides:
              <span
                style={{
                  fontWeight: "500",
                  color: "#0000008A",
                  fontFamily: "Montserrat,sans-serif",
                  marginLeft: "0.3rem",
                }}
              >
                {sheetStats.totalRides}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Title;
