import { Requests } from "../../../../services";
import moment from "moment";

export type ThunkCommissionRepo = {
    addCommission: (obj: any) => any;
    addCustomerCommission: (obj:any) => any;
    getCommissions: (obj: any) => any;
    getCustomerCommissions:(id: string) => any;
    updateCommission: (id:  any, obj: any) => any;
    deleteCommission: (id:  any) => any;
};

const ThunkCommission: ThunkCommissionRepo = {
    getCommissions: (value) => (dispatch: any, getState: any, mobioApi: any) => {
        const {
            userInfo: { token },
          } = getState();
          
          const api: Requests = mobioApi(token);
      
          return api.getCommissions(value)
            .then((res: any) => {
                res.data.map((item: any)=>{

                  let startDate = moment().format("YYYY-MM-DD")
                  let startDateUtc = moment.utc(startDate + " " + item.toTime);

                  item.toTime = moment(startDateUtc).local().format("HH:mm:ss");

                  let fromDate = moment().format("YYYY-MM-DD");
                  let fromDateUtc = moment.utc(fromDate + " " + item.fromTime);
                  
                  item.fromTime = moment(fromDateUtc).local().format("HH:mm:ss");
                })

                return res.data;
            })
            .catch((error: any) => {
              if (error.code === "ECONNABORTED") {
                dispatch(
                    alert("The API request timed out. Please Refresh the page")
                );
              } else {
                let errorMsg = error;
                if (error?.response?.data?.msg) {
                  errorMsg = error?.response?.data?.msg;
                }
                alert(errorMsg);
              }
            });
    },
    getCustomerCommissions: (id) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
          userInfo: { token },
        } = getState();
        
        const api: Requests = mobioApi(token);
    
        return api.getCustomerCommissions(id)
          .then((res: any) => { 
              if(Object.keys(res.data).length !== 0){
                res.data.map((item: any)=>{

                  // let startDate = moment().format("YYYY-MM-DD")
                  // let startDateUtc = moment.utc(startDate + " " + item.toTime);
  
                  // item.toTime = moment(startDateUtc).local().format("HH:mm:ss");
  
                  // let fromDate = moment().format("YYYY-MM-DD");
                  // let fromDateUtc = moment.utc(fromDate + " " + item.fromTime);
                  
                  // item.fromTime = moment(fromDateUtc).local().format("HH:mm:ss");

                  let startDate = moment().format("YYYY-MM-DD");
                  item.toTime = moment(`${startDate} ${item.toTime}`).format("HH:mm:ss");
                
                  let fromDate = moment().format("YYYY-MM-DD");
                  item.fromTime = moment(`${fromDate} ${item.fromTime}`).format("HH:mm:ss");
                })
  
                return res.data;
              }else{
                return [];
              }
          })
          .catch((error: any) => {
            if (error.code === "ECONNABORTED") {
              dispatch(
                  alert("The API request timed out. Please Refresh the page")
              );
            } else {
              let errorMsg = error;
              if (error?.response?.data?.msg) {
                errorMsg = error?.response?.data?.msg;
              }
              alert(errorMsg);
            }
          });
    },
    addCommission: (obj) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      
      const api: Requests = mobioApi(token);
  
      return api.addCommission(obj)
        .then((res: any) => {
            alert("Rule is successfully added.")
            return true;
        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
                alert("The API request timed out. Please Refresh the page")
            );
          } else {
            let errorMsg = error;
            if (error?.response?.data?.msg) {
              errorMsg = error?.response?.data?.msg;
            }
            alert(errorMsg);
          }
        });
    },
    addCustomerCommission: (obj) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      
      const api: Requests = mobioApi(token);
  
      return api.addCustomerCommission(obj)
        .then((res: any) => {
            alert("Rule is successfully added.")
            return true;
        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
                alert("The API request timed out. Please Refresh the page")
            );
          } else {
            let errorMsg = error;
            if (error?.response?.data?.msg) {
              errorMsg = error?.response?.data?.msg;
            }
            alert(errorMsg);
          }
        });
    },
    updateCommission: (id, obj) => (dispatch: any, getState: any, mobioApi: any) => {
        const {
          userInfo: { token },
        } = getState();
        
        const api: Requests = mobioApi(token);
    
        return api.updateCommission(id, obj)
          .then((res: any) => {
              alert("Rule is successfully updated.")
          })
          .catch((error: any) => {
            if (error.code === "ECONNABORTED") {
              dispatch(
                  alert("The API request timed out. Please Refresh the page")
              );
            } else {
                console.log(error)
              let errorMsg = error;
              if (error?.response?.data?.msg) {
                errorMsg = error?.response?.data?.msg;
              }
              alert(errorMsg);
            }
          });
    },
    deleteCommission: (id) => (dispatch: any, getState: any, mobioApi: any) => {
        const {
          userInfo: { token },
        } = getState();
        
        const api: Requests = mobioApi(token);
    
        return api.deleteCommission(id)
          .then((res: any) => {
              alert("Rule is successfully deleted.")
          })
          .catch((error: any) => {
            if (error.code === "ECONNABORTED") {
              dispatch(
                  alert("The API request timed out. Please Refresh the page")
              );
            } else {
                console.log(error)
              let errorMsg = error;
              if (error?.response?.data?.msg) {
                errorMsg = error?.response?.data?.msg;
              }
              alert(errorMsg);
            }
          });
    },
};

export default ThunkCommission;
